export default {
    name: "small-table",
    props: {
        item: {
            type: Object,
            default: {}
        },
        sizes: {
            type: Object,
            default: () => {
            }
        }
    },
    components: {},
    mounted: {},
    data() {
        return {}
    }
}
