import {mapMutations} from "vuex";

//sections
export default {
    name: "snow-load",
    props: {
        list: {
            type: Array,
            default: []
        },
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 0
        },
        step: {
            type: Number,
            default: 0
        },
        inputValue: {
            type: Number,
            default: 0
        },
        endPoint: {
            type: Number,
            default: () => 0,
        },
        firstPoint: {
            type: Number,
            default: () => 0,
        },
    },
    components: {},
    data() {
        return {
            unitCollection: '',
            range: {
                value: 0
            },
            rangeV: '',
            rangeThumb: '',
            value: 0.25,
            thumbPosition: '',
        }
    },
    mounted() {
        this.range = this.$refs['range-slider']
        this.rangeV = this.$refs['range-value']
        this.rangeThumb = this.$refs['range-thumb']

        this.rangeV.style.left = `calc(0% )`;
        this.rangeThumb.textContent = this.range.value
        this.$refs['input-color'].style.width = 0
        this.value = eval(this.list[0])
        if (this.$refs['division']) {
            this.unitCollection = -(this.$refs['division'][0].offsetLeft - 2) + 'px'
            if (this.$refs['division'][0].offsetLeft >= 30) {
                this.unitCollection = -(this.$refs['division'][0].offsetLeft + 2) + 'px'
            }
        }


    },
    created() {

    },
    watch: {
        value: {

            handler(newVal) {
                if (newVal) {
                    const newValue = Number((this.range.value - this.range.min) * 100 / (this.range.max - this.range.min));
                    this.rangeThumb.textContent = this.range.value
                    this.rangeV.style.left = `calc(${newValue}% )`;
                    this.$refs['input-color'].style.width = this.rangeV.style.left
                    this.getValue()
                }
            }
        },
        endPoint: {
            handler(newVal) {
                if (newVal) {
                    this.range.max = this.endPoint
                    const newValue = Number((this.range.value - this.firstPoint) * 100 / (this.endPoint - this.firstPoint));
                    this.rangeThumb.textContent = this.range.value
                    this.rangeV.style.left = `calc(${newValue}% )`;
                    this.$refs['input-color'].style.width = this.rangeV.style.left
                    this.getValue()
                }
            }
        }
    },
    computed: {},

    methods: {
        getValue() {
            this.value = this.range.value
            this.$emit('input', this.value);
        },
        showProducts() {
            this.$emit('show-products')
        },
        ...mapMutations({})
    }
}
